<script>
import Nav from '@/components/global/Navbar';
import Footer from "@/components/global/Footer";

export default {
  components: {
    Nav,
    Footer
  }
};
</script>

<template>
  <div class="wrapper">
    <Nav />
    <slot />
    <Footer />
  </div>
</template>

<template>
  <section class="empresa_section" id="empresa">
    <b-container>
      <b-col lg="12" xl="6">
        <h1 class="title-section">Sobre <span>la empresa</span></h1>
        <div v-for="(item, index) in DESCRIPCION" :key="index">
          <p>{{ item }}</p>
        </div>
          <!-- <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore
        </p>
        <p>
          OOdo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor
          sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore .
        </p> -->
      </b-col>
    </b-container>
    <img src="@/assets/img/Banner2.jpg" />
  </section>
</template>

<script>
import { baseInstanceAxios } from "@/assets/js/axiosBase.js";
var _ctx = "";
export default {
  name: "About",
  props: {
    artists: String,
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      DESCRIPCION: [],
    };
  },
  created: function () {
    _ctx = this.API_URL;
    this.consultaDescripcion();
  },
  methods: {
    consultaDescripcion: function () {
      var api_url = _ctx + `mara/ConsultaDescripcion`;
      baseInstanceAxios({
        method: "POST",
        url: api_url,
      })
        .then((response) => {
          console.log("response===>", response);
          let data = response.data.dto.descripcion;
          if (data != null && data != "") {
            var arrayData = data.split("\n").filter((p) => p != "");
            console.log("array===>", arrayData);
            this.DESCRIPCION = arrayData;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>

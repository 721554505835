import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from 'vuex-persistedstate';

import VueCookie from 'vue-cookie';

Vue.use(Vuex);
Vue.use(VueCookie);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => Vue.cookie.get(key),
                setItem: (key, value) =>
                    Vue.cookie.set(key, value, { expires: 3, secure: false }),
                removeItem: key => Vue.cookie.delete(key)
            }
        })
    ],
    state: {
        email: '',
		celular: '',
		whatsapp:''
    },

    getters: {},

    mutations: {
       
        mutateEmail: function(state, payload) {
            state.email = payload;
        },
		mutateCelular: function(state, payload) {
            state.celular = payload;
        },
		mutateWhatsapp: function(state, payload) {
            state.whatsapp = payload;
        }
        
    },

    actions: {
        updateEmail: function({ commit }, payload) {
            commit('mutateEmail', payload);
        },
		updateCelular: function({ commit }, payload) {
            commit('mutateCelular', payload);
        },
		updateWhatsapp: function({ commit }, payload) {
            commit('mutateWhatsapp', payload);
        },
        
    }
});

<template>
  <div>
    <section class="header">
      <!-- <img src="@/assets/img/Banner.jpg" /> -->
      <div class="header-title">
        <b-container>
          <h6>
            Distribuidores <br />
            de <span>Skyjack</span> en Perú
          </h6>
          <p>Alquiler y venta de equipos de elevación</p>
        </b-container>
      </div>
    </section>
    <b-container id="products">
      <div class="whatsapp-contenedor">
        <div class="recipiente">
          <div class="logo-whatsapp">
            <img
              @click="mostrarWS()"
              class="foto-whatsapp"
              src="@/assets/img/Logo_whatsapp.png"
              alt=""
            />
          </div>
          <p>Cotizar</p>
        </div>
      </div>

      <div v-for="item in CATEGORIAS" :key="item.idCategoria" :id="'section'+item.idCategoria">
		<h3 class="title-section underline">{{ item.nombreCategoria_primero }}<span>{{ item.nombreCategoria_segundo }}</span></h3>
      
        <template v-if="isResponsive">
          
          <div v-for="itemP in item.productos" :key="itemP.id">
            <transition name="fade">
            <div v-show="itemP.flagVisible" class="card">
              <h6>{{ item.nombre }}</h6>
              <owl-carousel
                :nav="false"
                :items="1"
                ref="carousel-img"
              >
                <template
                  v-for="itemImagen in itemP.lista_Archivos"
                >
                  <img class="product" :src="itemImagen.urlImagen" :key="itemImagen.id" />
                </template>
              </owl-carousel>
              <b-form-row>
                <b-col sm="6">
                  <ul>
                    <li>
                      <img src="@/assets/img/altura.png" /><strong>{{
                        itemP.altura_Trabajo.toFixed(2) + "m"
                      }}</strong>
                      de altura de trabajo
                    </li>
                    <li>
                      <img src="@/assets/img/modelo.png" />{{ itemP.modelo }} {{ itemP.marca }}
                    </li>
                    <li>
                      <img src="@/assets/img/energia.png" />{{ itemP.tipo }}
                    </li>
                  </ul>
                </b-col>
                <b-col sm="6" style="align-self: center">
                  <button class="btn btn_download" @click="mostrarPdf(itemP)">
                    <div>
                      <h6>Descargar PDF</h6>
                      <span>de especificaciones</span>
                    </div>
                    <img src="@/assets/img/descargar.png" />
                  </button>
                </b-col>
              </b-form-row>
            </div>
            </transition>
          </div>
        </template>
        <template v-else>
          <carousel
            :navigationEnabled="true"
            :paginationEnabled="false"
            :mouseDrag="false"
            :per-page="2"
            ref="carousel"
            :navigation-next-label="arrowRightComputed"
            :navigation-prev-label="arrowLeftComputed"
          >
            <slide v-for="itemP in item.productos" :key="itemP.id">
              <div class="card">
                <h6>{{ itemP.nombre }}</h6>
                <owl-carousel
                  class="carousel_img"
                  :nav="false"
                  :items="1"
                  ref="carousel-img"
                >
                  <template
                    v-for="itemImagen in itemP.lista_Archivos"
                    
                  >
                    <img class="product" :src="itemImagen.urlImagen" :key="itemImagen.id" />
                  </template>
                </owl-carousel>

                <b-form-row>
                  <b-col md="6" xs="12">
                    <ul>
                      <li>
                        <img src="@/assets/img/altura.png" /><strong>{{itemP.altura_Trabajo.toFixed(2) + "m"}}</strong>
                        de altura de trabajo
                      </li>
                      <li>
                        <img src="@/assets/img/modelo.png" />{{ itemP.modelo + " - " + itemP.marca }}
                      </li>
                      <li>
                        <img src="@/assets/img/energia.png" />{{ itemP.tipo }}
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="6" xs="12" style="align-self: center">
                    <button class="btn btn_download" @click="mostrarPdf(itemP)">
                      <div>
                        <h6>Descargar PDF</h6>
                        <span>de especificaciones</span>
                      </div>
                      <img src="@/assets/img/descargar.png" />
                    </button>
                  </b-col>
                </b-form-row>
              </div>
            </slide>
          </carousel>
        </template>
        <div v-show="item.productos.length > 1">
          <div v-if="item.flagTodos">
            <a
              :href="'#section'+item.idCategoria"
              v-smooth-scroll
              class="btn btn_show"
              @click="mostrarProductos(item.idCategoria, false)"
            >
              Ocultar  
            </a>
          </div>
          <div v-else>
            <button
              class="btn btn_show active"
              @click="mostrarProductos(item.idCategoria, true)"
            >
              ver más  
            </button>
          </div>
        </div>
      </div>
    </b-container>
    <About  artists="algo de texto" />
    <Contact />
  </div>
</template>
<script>
import { baseInstanceAxios } from "@/assets/js/axiosBase.js";
import About from "@/components/About.vue";
import Contact from "@/components/Contact.vue";
import arrowLeft from "@/assets/img/arrow_left.png";
import arrowRight from "@/assets/img/arrow_right.png";
import owlCarousel from 'vue-owl-carousel'

var _ctx = '';

export default {
  components: {
    Contact,
    About,
    'owl-carousel': owlCarousel 
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      CATEGORIAS: [],
      DESCRIPCION:"",
      //CELULAR: "",
      //EMAIL: "",
      //WHATSAPP: "",
      isResponsive: false,
      imgArrowLeft: arrowLeft,
      imgArrowRight: arrowRight,
      loading: false,
    };
  },
  created: function () {
	 
    _ctx = this.API_URL;
    this.listarCategoriaProducto();
  },
  methods: {
    mostrarProductos: function (categoriaId, visible) {
      let categoria = this.CATEGORIAS.filter(
        (p) => p.idCategoria == categoriaId
      )[0];
      //console.log("mostrarProductos====>", categoria, visible, categoria);
      categoria.productos.forEach(function (element) {
        element.flagVisible = visible;
      });
      if (!visible) {
        categoria.productos[0].flagVisible = true;
      }
      categoria.flagTodos = visible;
    },
    mostrarPdf: function (item) {
      window.open(item.urlPdf);
    },
    mostrarWS: function () {

      var find = " ";
      var re = new RegExp(find, "g");

      let url = `https://wa.me/${this.WHATSAPP.replace(re, "")}`;
      console.log(url);
      window.open(url);
    },
    listarCategoriaProducto: function () {
      var api_url = _ctx + `mara/ListarProductos`;
      baseInstanceAxios({
        method: "POST",
        url: api_url,
      })
        .then((response) => {
          let data = response.data;
          this.CATEGORIAS = data.dto.categoriaProducto;
          this.DESCRIPCION=data.dto.descripcion;
		  this.CELULAR = data.dto.celular;
		  this.EMAIL = data.dto.contactoEmail;
          //this.WHATSAPP = "+51 " + data.dto.whatsapp;
		  this.WHATSAPP = "51 " + data.dto.whatsapp;

          console.log("data====>", this.WHATSAPP);
        })
        .catch(function (error) {
          console.log(error);
           
        });
    },
  },
  computed: {
    arrowLeftComputed() {
      return `<img src="${this.imgArrowLeft}" />`;
    },
    arrowRightComputed() {
      return `<img src="${this.imgArrowRight}" />`;
    },
	EMAIL: {
        get() {
            return this.$store.state.email;
        },
        set(value) {
            this.$store.commit('mutateEmail', value);
        }
    },
	CELULAR: {
        get() {
            return this.$store.state.celular;
        },
        set(value) {
            this.$store.commit('mutateCelular', value);
        }
    },
	WHATSAPP: {
        get() {
            return this.$store.state.whatsapp;
        },
        set(value) {
            this.$store.commit('mutateWhatsapp', value);
        }
    },
  },
  mounted() {
    // this.$refs.carousel.destroy()
    // console.log('this.Carousel',this.$refs.carousel)
    if (window.innerWidth < 1100) {
      this.isResponsive = true;
      console.log("responsive");
    } else {
      this.isResponsive === false;
    }
  },
};
</script>

<template>
  <div class="footer-section">
		<div class="container">
      <div class="row contenedor-contenido">
        <div class="col-sm-12 col-xl-5 contenedor-imagen" >
          <img src="@/assets/img/LogoMara2.png" alt="" class="logoMara">
        </div>
        <div class="col-sm-6 col-xl-4" >
          <ul>
            <li><a href="#products">Productos</a></li>
            <li><a href="#empresa">Empresa</a></li>
            <li><a href="#contact">Contacto</a></li>
          </ul>
        </div>
        <div class="col-sm-6 col-xl-3">
          <ul >
            <li>{{celular}}</li>
            <li><a class="correo" :href="`mailto:${email}`">{{email}}</a></li>
          </ul>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
 
export default {
   
  computed: {
     email: {
        get() {
            return this.$store.state.email;
        },
    },
	celular: {
        get() {
            return this.$store.state.celular;
        },
    },
	whatsapp: {
        get() {
            return this.$store.state.whatsapp;
        },
    },
        
  },
 
};
</script>
<template>
  <section class="contact_section" id="contact">
    <b-container>
      <h1>Contacto</h1>
      <b-form id="app">
        <b-row>
          <b-col lg="6">
            <b-form-group>
              <b-form-input
                id="txtNombres"
                v-model="txtNombres"
                autocomplete="off"
                type="text"
                placeholder="Nombres"
                required
                @blur="validateNombreKey"
              ></b-form-input>
              <span v-if="valNombres" class="error-label"
                >Dede ingresar el nombre</span
              >
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <b-form-input
                id="txtApellidos"
                v-model="txtApellidos"
                autocomplete="off"
                type="text"
                placeholder="Apellidos"
                required
                @blur="validateApellidosKey"
              ></b-form-input>
              <span v-if="valApellidos" class="error-label"
                >Dede ingresar el apellidos</span
              >
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <b-form-input
                id="txtEmail"
                v-model="txtEmail"
                autocomplete="off"
                type="email"
                placeholder="E-mail"
                required
                @blur="validateEmailKey"
              ></b-form-input>
              <span v-if="valEmail" class="error-label"
                >Dede ingresar el correo electrónico</span
              >
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group>
              <b-form-input
                id="txtTelefono"
                v-model="txtTelefono"
                autocomplete="off"
                type="text"
                placeholder="Teléfono"
                required
                @blur="validateTelefonoKey"
              ></b-form-input>
              <span v-if="valTelefono" class="error-label"
                >Dede ingresar el teléfono</span
              >
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group>
              <b-form-textarea
                id="txtMensaje"
                v-model="txtMensaje"
                autocomplete="off"
                type="text"
                placeholder="Escriba su mensaje aquí"
                required
                @blur="validateMensajeKey"
                rows="2"
              ></b-form-textarea>
              <span v-if="valMensaje" class="error-label"
                >Dede ingresar el comentario</span
              >
            </b-form-group> </b-col
          ><b-col lg="6"> </b-col>
        </b-row>
        <button type="button" @click="registrarContacto" class="btn">
		
         <div>
		
        <b-modal id="modal_confirmation" centered  ok-only ok-title="Aceptar">
          <p class="my-4">
            <b-icon icon="check-circle" animation="throb">
            </b-icon>Se envío su información</p>
        </b-modal>
        </div>
          ENVIAR
        </button>
		 
      </b-form>
    </b-container>
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      ></loading> 
    </div>
  </section>
</template>

<script>
import { baseInstanceAxios } from "@/assets/js/axiosBase.js";
import Loading  from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var _ctx = '';
export default {
  components: {
    Loading
  },
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      txtNombres: "",
      txtApellidos: "",
      txtEmail: "",
      txtTelefono: "",
      txtMensaje: "",

      valNombres: false,
      valApellidos: false,
      valEmail: false,
      valTelefono: false,
      valMensaje: false,

      isLoading: false,
      fullPage: true,
    };
  },
  created: function () {
	_ctx = this.API_URL;
  },
  methods: {
    validateNombreKey: function () {
      this.valNombres = false;
      this.checkForm();
    },
    validateApellidosKey: function () {
      this.valApellidos = false;
      this.checkForm();
    },
    validateEmailKey: function () {
      this.valEmail = false;
      this.checkForm();
    },
    validateTelefonoKey: function () {
      this.valTelefono = false;
      this.checkForm();
    },
    validateMensajeKey: function () {
      this.valMensaje = false;
      this.checkForm();
    },
    checkForm: function () {
      let error = 0;
      var re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;

      if (this.txtNombres == "") {
        this.valNombres = true;
        error = error + 1;
      }
      if (this.txtApellidos == "") {
        this.valApellidos = true;
        error = error + 1;
      }
      if (this.txtEmail == "" || !re.test(this.txtEmail)) {
        this.valEmail = true;
        error = error + 1;
      }
      if (this.txtTelefono == "") {
        this.valTelefono = true;
        error = error + 1;
      }
      if (this.txtMensaje == "") {
        this.valMensaje = true;
        error = error + 1;
      }

      return error == 0;
    },

    registrarContacto: function () {
      let valid = this.checkForm();
      if (valid) {
        this.isLoading = true;
        let data = {
          dto: {
            Nombres: this.txtNombres,
            Apellidos: this.txtApellidos,
            Correo_Electronico: this.txtEmail,
            Telefono: this.txtTelefono,
            Comentario: this.txtMensaje,
          },
        };
 
		var api_url = _ctx + `mara/RegistrarContacto`;
        baseInstanceAxios({
          method: "POST",
          url: api_url,
          data: data,
        })
          .then(() => {
            this.resetInput();
            this.isLoading = false;
            this.$bvModal.show('modal_confirmation')
          })
          .catch(function (error) {
            this.isLoading = false;
            console.log(error);
          });
      }
    },
    resetInput: function () {
      console.log("resetInput");
      this.txtNombres = "";
      this.txtApellidos = "";
      this.txtEmail = "";
      this.txtTelefono = "";
      this.txtMensaje = "";

      this.valNombres = false;
      this.valApellidos = false;
      this.valEmail = false;
      this.valTelefono = false;
      this.valMensaje = false;
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>

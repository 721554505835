import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueCarousel from 'vue-carousel';
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueSmoothScroll from 'vue2-smooth-scroll';

import Default from "./layouts/Default.vue";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueCarousel);
Vue.use(VueSmoothScroll, {
  duration: 1000,
  updateHistory: false,
  offset: -100,
})
Vue.config.productionTip = false;

Vue.component("default-layout", Default);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

<template>
  <b-navbar toggleable="lg" fixed="top" id="navbar">
    <!-- <b-navbar toggleable="lg" id="navbar"> -->
    <b-container>
      <b-navbar-brand href="#">
        <img  v-if="navChangeColor" class="logo" v-bind:src="imagenNav"/>
        
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" >
       
		<a class="number primer-numero-collapse" :href="`tel:${celular}`">{{celular}}</a>
        <img src="@/assets/img/menu.png"  />
        
      </b-navbar-toggle>
      <!-- <div class="contact">
        <span>+51 961 229 112</span>
        <span>contacto@mararental.com</span>
      </div> -->
      
      <b-collapse id="nav-collapse" is-nav>
        
        <!-- <b-navbar-nav>
          <b-nav-item href="#">Link</b-nav-item>
          <b-nav-item href="#" disabled>Disabled</b-nav-item>
        </b-navbar-nav> -->
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto contenedor-colapsador">
          <span class="primer-numero">{{celular}}</span>
          <div class="segundo-numero">
            <span class="number">{{celular}}</span>
            <a class="number" :href="`mailto:${email}`">{{email}}</a>
          </div>
          <div class="contenedor-nav-item">
            <li class="nav-item menu-item">
              <a class="nav-link" href="#products" v-smooth-scroll>Productos</a>
            </li>
            <li class="nav-item menu-item">
              <a class="nav-link" href="#empresa" v-smooth-scroll>Empresa</a>
            </li>
            <li class="nav-item menu-item">
              <a class="nav-link" href="#contact" v-smooth-scroll>Contacto</a>
            </li>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>
<script>
import LogoWeb from '@/assets/img/LogoMara.jpg'
import LogoMobile from '@/assets/img/MaraRentalNav2.png'
export default {
  data() {
    return {
      LogoNav1: LogoWeb,
      LogoNav2: LogoMobile,
      imagenNav: LogoWeb
    };
  },
  mounted() {
    this.navChangeColor();
  },
  computed: {
    email: {
        get() {
            return this.$store.state.email;
        },
    },
	celular: {
        get() {
            return this.$store.state.celular;
        },
    },
	whatsapp: {
        get() {
            return this.$store.state.whatsapp;
        },
    },
        
  },
  methods: {
    navChangeColor() {
      let navbar = document.querySelector("#navbar");
      let _this = this;
      window.onscroll = () => {
        
        if (window.scrollY > 20) {
          console.log(_this)
          navbar.classList.add("header-scrolled");
          _this.imagenNav=_this.LogoNav2
        } else {
          navbar.classList.remove("header-scrolled");
          _this.imagenNav=_this.LogoNav1
        }
      };
    },
  },
};
</script>
